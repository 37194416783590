<template>
  <div id="app">
    <simulate-user-modal v-model="simulateUserModalVisible" />
    <b-navbar
      toggleable="md"
      type="dark"
      :variant="environment !== 'production' ? 'staging' : 'primary'"
      class="gid-header"
      :class="{ 'flex-wrap': !updateExists }"
    >
      <back-button class="mr-2" />
      <router-link to="/" class="d-flex align-items-center flex-wrap">
        <b-navbar-brand to="/" class="mr-auto">GID ADMIN</b-navbar-brand>
        <b-badge
          v-if="environment != 'production'"
          variant="warning"
          class="mx-2"
        >
          {{ environment }}
        </b-badge>
      </router-link>

      <b-navbar-toggle
        v-if="!updateExists"
        target="nav_collapse"
      ></b-navbar-toggle>
      <b-collapse v-if="isAuthenticated" is-nav id="nav_collapse">
        <b-navbar-nav>
          <b-nav-item to="/jobs" v-if="canAccessRoute({ name: 'jobs' })">
            Jobs
          </b-nav-item>
          <b-nav-item
            to="/partners"
            v-if="canAccessRoute({ name: 'partners' })"
          >
            Partners
          </b-nav-item>
          <b-nav-item-dropdown
            text="Integrations"
            v-if="
              canAccessRoute({ name: 'rating-admin' }) ||
              canAccessRoute({ name: 'order-intake-forms-admin' })
            "
            right
          >
            <b-dropdown-item
              to="/rating-admin"
              v-if="canAccessRoute({ name: 'rating-admin' })"
            >
              Rating Admin
            </b-dropdown-item>
            <b-dropdown-item
              to="/order-intake-forms-admin"
              v-if="canAccessRoute({ name: 'order-intake-forms-admin' })"
            >
              Order Intake Forms Admin
            </b-dropdown-item>
            <b-dropdown-item
              :href="formsBackofficeUrl"
              v-if="canAccessRoute({ name: 'order-intake-forms-admin' })"
              target="_blank"
            >
              OI Forms Admin in New Tab
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            to="/product-orders"
            v-if="canAccessRoute({ name: 'product-orders' })"
          >
            Product orders
            <ProductOrdersPendingCount />
          </b-nav-item>
          <b-nav-item-dropdown
            v-if="canAccessRoute({ name: 'accounting' })"
            right
          >
            <template #button-content>Accounting</template>
            <b-dropdown-item
              to="/accounting/drafts"
              v-if="canAccessRoute({ name: 'accounting-drafts' })"
            >
              Draft Documents
            </b-dropdown-item>
            <b-dropdown-item
              to="/accounting/documents"
              v-if="canAccessRoute({ name: 'accounting-documents' })"
            >
              Documents
            </b-dropdown-item>
            <b-dropdown-item
              to="/accounting/payments"
              v-if="canAccessRoute({ name: 'accounting-payments' })"
            >
              Payments
            </b-dropdown-item>
            <b-dropdown-item
              to="/accounting/documents-balances"
              v-if="canAccessRoute({ name: 'documents-balances' })"
            >
              Documents Balances
            </b-dropdown-item>
            <b-dropdown-item
              to="/accounting/recheck-drafts"
              v-if="canAccessRoute({ name: 'accounting-recheck-drafts' })"
            >
              Recheck Drafts
            </b-dropdown-item>
            <b-dropdown-item
              to="/accounting/configuration"
              v-if="canAccessRoute({ name: 'accounting-configuration' })"
            >
              Configuration
            </b-dropdown-item>
            <b-dropdown-item
              to="/accounting/exports"
              v-if="canAccessRoute({ name: 'accounting-exports' })"
            >
              Exports
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/data" v-if="canAccessRoute({ name: 'data' })">
            Data
          </b-nav-item>
          <b-nav-item-dropdown
            text="Tools"
            v-if="canAccessRoute({ name: 'tools' })"
            right
          >
            <b-dropdown-item
              to="/email-tester"
              v-if="canAccessRoute({ name: 'email-tester' })"
            >
              Email Tester
            </b-dropdown-item>

            <b-dropdown-item
              to="/chat-view/asst_kQh0hsLCDWdfKued0U9fAuLR"
              v-if="canAccessRoute({ name: 'chat-view' })"
            >
              Wiki Chat Bot
            </b-dropdown-item>

            <b-dropdown-item
              to="/chat-view/asst_UEk3W5aOb7PhmvLlnPFBui2u"
              v-if="canAccessRoute({ name: 'chat-view' })"
            >
              Platform Code Chat Bot
            </b-dropdown-item>

            <b-dropdown-item
              to="/email-queues"
              v-if="canAccessRoute({ name: 'email-queues' })"
            >
              Email Queues
            </b-dropdown-item>
            <b-dropdown-item
              to="/jobs-service-queues"
              v-if="canAccessRoute({ name: 'jobs-service-queues' })"
            >
              Jobs Service Queues
            </b-dropdown-item>
            <b-dropdown-item
              to="/crud-queues"
              v-if="canAccessRoute({ name: 'crud-queues' })"
            >
              Crud Queues
            </b-dropdown-item>
            <b-dropdown-item
              to="/documents-service-queues"
              v-if="canAccessRoute({ name: 'documents-service-queues' })"
            >
              Documents Service Queues
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isTechnicalSupport"
              @click="simulateUserModalVisible = true"
            >
              Simulate User
            </b-dropdown-item>
            <!-- <b-dropdown-item to="/account" v-if="canAccessRoute({ name: 'account' })">Account</b-dropdown-item> -->
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="logout">Logout</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <b-collapse v-else is-nav id="nav_collapse">
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/login">Login</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <refresh-button v-if="updateExists" :onRefresh="refreshApp" />
    </b-navbar>
    <b-container fluid>
      <b-spinner v-if="hasAccess === null" variant="secondary" class="m-2" />
      <router-view v-else-if="hasAccess" />
      <div v-else class="text-center">
        <h4 class="m-5">{{ $t('no-access.title') }}</h4>
        <p v-if="isAuthenticated">{{ $t('no-access.description') }}</p>
        <i18n v-else path="no-access.login" tag="p" class="mb-0">
          <router-link slot="login" to="/login">
            {{ $t('no-access.login-link') }}
          </router-link>
        </i18n>
      </div>
    </b-container>
    <add-to-home-screen app-name="GID Admin" />
    <offline />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PURGE_AUTH } from '@gid/vue-common/store/auth.module/types';
import update from '@gid/vue-common/components/mixins/update';
import { appUrl } from '@gid/vue-common/app-settings/appUrls';
import { checkRouteAccess } from '@gid/vue-common/store/auth.module';
import ProductOrdersPendingCount from './views/product-orders/ProductOrdersPendingCount.vue';
import SimulateUserModal from './components/SimulateUserModal.vue';
import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';

export default {
  components: {
    ProductOrdersPendingCount,
    SimulateUserModal,
  },
  mixins: [update],
  computed: {
    ...mapGetters([
      'access_token',
      'isAuthenticated',
      'environment',
      'contactProfiles',
      'user',
    ]),
    isTechnicalSupport() {
      return this.contactProfiles.includes(
        ContactProfileEnum.TECHNICAL_SUPPORT,
      );
    },
    hasAccess() {
      return checkRouteAccess({
        route: this.$route,
        user: this.user,
        isAuthenticated: this.isAuthenticated,
      });
    },
    formsBackofficeUrl() {
      const _url = `${appUrl(
        'order-intake-forms-backoffice',
        '/',
      )}?${new URLSearchParams({ jwt: this.access_token })}`;
      console.log('constr url', _url);
      return _url;
    },
  },
  data() {
    return {
      simulateUserModalVisible: false,
    };
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH);
    },
    canAccessRoute(routes) {
      if (!Array.isArray(routes)) {
        routes = [routes];
      }
      for (const route of routes) {
        const resolved = this.$router.resolve(route);
        if (
          resolved &&
          checkRouteAccess({
            route: resolved.route,
            user: this.user,
            isAuthenticated: this.isAuthenticated,
          })
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
.gid-header {
  &.navbar {
    flex-wrap: nowrap;
    padding: 0.7rem 1rem;
    gap: 0.5rem 1rem;
  }
}
</style>
